body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main_app {
  background-color: white;
}

.light_button {
  color: #f7f7f9 !important;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(#6e42cd, #c86883) !important;
  margin-top: 20px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.light_button:hover {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2);
}

.main_container {
  max-width: 700px;
  margin: 20px auto;
}

.section_style {
  margin-top: 20px;
  border-radius: 10px;
  background-color: #f7f7f9;
  padding: 20px;
}

.h2_home {
  background-color: black;
}

.profile_pic {
  width: 40%;
  margin: 25px;
}

.name_intro {
  margin: 18px;
}

.intro_text {
  margin: 20px;
  font-size: 1.05rem;
}

.skill_icon {
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 25px;
  margin: 15px;
  align-self: center;
  align-content: center;
  align-items: center;
  border-radius: 10px;
}

.skill_sub {
  padding-left: 0.7em;
  align-self: flex-start !important;
}

.image_shadow {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.blog_grid {
  transition: transform 0.2s;
  /* Animation */
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0 auto;
}

.blog_grid:hover {
  transform: scale(1.1);
}

@media only screen and (max-width: 768px) {
  /* For tablets: */
}

@media only screen and (max-width: 479px) {

  /* For mobile phones: */
  .project_brief {
    margin-top: 20px;
  }

  .blog_grid {
    margin-top: 25px;
  }
}

#carousel {
  width: 100%;

  overflow-x: scroll;
  white-space: nowrap;
}

#carousel .slide {
  display: inline-block;
  margin-left: 10px;
  margin-right: 20px;
}
#carousel .slide:hover {
  cursor: pointer;
}